// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

type Product = {
    MENSAL: {
        STUP: string;
        PE: string;
        ME1: string;
        ME2: string;
    };
    ANUAL: {
        STUP: string;
        PE: string;
        ME1: string;
        ME2: string;
    };
};

export const environment = {
    production: false,
    apiKey: 'oeq5Hjzbu36eoy4PNFo2d4A2tjSph0qWUQLC7OO8',
    ablyApiKey: '_P_hDw.GmTmWw:uS8-81eMlryJgOZXAIK3kNtsOnYwfjUXxH8Gyj6veL8'
};

export const COGNITO = {
    identityPoolId: 'us-east-1:b26a70f1-fe33-4cb2-bd0b-af7663cc8c6c',
    region: 'us-east-1',
    userPoolId: 'us-east-1_OBhN3vxPV',
    userPoolWebClientId: '2glkk4cmtfhlao6smoc0mst7g0',
};

export const S3_BUCKET = {
    name: 'employee-picture-dev',
    region: 'us-east-1',
};

// export const SERVER_URL = 'https://prro2dzq23.execute-api.us-east-1.amazonaws.com/dev';
export const SERVER_URL = 'https://dev.alphatime.net';
export const REGISTER_APP_URL = 'http://localhost:4300';
export const WS_URL = 'wss://cawh1wpibf.execute-api.us-east-1.amazonaws.com/dev';
export const EMPLOYEE_IMG_URL = 'https://dsrhdxjpe268z.cloudfront.net/public/';
export const JUSTIFICATION_FILE_URL = 'https://dzs1713b04lqk.cloudfront.net/public/';
export const STRIPE_API = 'pk_test_BX6jxr5te1GmVjJ5h9QF5RV9007JNcnLGK';


export const PRODUCTS: Product = {
    MENSAL: {
        STUP: 'price_1J4bhZLmDyW1w19xse6xJjch',
        PE: 'price_1N6XSzLmDyW1w19x2iAdNSiX',
        ME1: 'price_1N6XPDLmDyW1w19x1Kr3eqEq',
        ME2: 'price_1N6XRSLmDyW1w19xF7pdQtSr'
    },
    ANUAL: {
        STUP: 'price_1J9bgtLmDyW1w19x1AtmtxAq',
        PE: 'price_1N6XTdLmDyW1w19xuR6w4aVX',
        ME1: 'price_1N6XQFLmDyW1w19xMPfjslXz',
        ME2: 'price_1N6XS4LmDyW1w19xNVeY7JPm'
    }
};

export const PUBLIC_URL = ['signature'];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
