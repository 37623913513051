import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from '../environments/environment';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class AppHttpInterceptors
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AppHttpInterceptors implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.get('Anonymous') !== null) {
            const newHeaders = request.headers.delete('Anonymous');
            const newRequest = request.clone({ headers: newHeaders });

            return next.handle(newRequest);
        }

        return from(Auth.currentSession())
            .pipe(
                switchMap((session: any) => {

                    const jwt = session.getIdToken().getJwtToken();
                    const withAuthRequest = request.clone({
                        setHeaders: {
                            'x-api-key': `${environment.apiKey}`,
                            Authorization: `${jwt}`,
                        }
                    });
                    console.log(withAuthRequest);
                    return next.handle(withAuthRequest);
                }),
                catchError((err: any) => {
                    console.log(err);
                    if (err && typeof err === 'object'  && err.error.message === 'CNPJ_IN_USE') {
                        return throwError(err.error.message);
                    }
                    return next.handle(request);
                })
            );

    }

}
